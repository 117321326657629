import React from 'react';
import { Trans } from '@lingui/macro';
import Header from './Header';
import Hero from './Hero';
import Block from './Block';
import bikeImage from './bike.png';
import globeImage from './globe.png';
import calendarImage from './calendar.png';
import Features from './Features';
import IndexSystemList from '../IndexSystemList';
import styles from './Home.module.scss';
import BlockTitle from './BlockTitle';
import Footer from '../Footer';

const Home = ({
    cityNum, analyzedMonth, systemList, locale, spaLink,
}) => (
    <>
        <Header locale={locale} />
        <Hero locale={locale} />
        <div className="container mb-5">
            <div className="row mb-5">
                <div className="col-12 col-md-4 mb-2">
                    <Block img={globeImage}>
                        {cityNum}
                        {' '}
                        <Trans id="index.staTrans.city" />
                    </Block>
                </div>
                <div className="col-12 col-md-4 mb-2">
                    <Block img={calendarImage}>
                        {analyzedMonth}
                        {' '}
                        <Trans id="index.staTrans.month" />
                    </Block>
                </div>
                <div className="col-12 col-md-4 mb-2">
                    <Block img={bikeImage}>
                        103,863,321
                        {' '}
                        <Trans id="index.staTrans.trip" />
                    </Block>
                </div>
            </div>
        </div>
        <div className={styles.wrapper}>
            <div className="container pb-5 pt-5">
                <Features />
                <div className="pt-5" id="systems">
                    <BlockTitle>
                        <Trans id="index.blockTitle.system" />
                    </BlockTitle>
                    <IndexSystemList systemList={systemList} locale={locale} spaLink={false} />
                </div>
            </div>
        </div>
        <Footer locale={locale} />
    </>
);

export default Home;
