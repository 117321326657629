import React from 'react';
import { Trans } from '@lingui/macro';
import cn from 'classnames';
import Image from 'next/image';
import featureStyles from './Features.module.scss';
import BlockTitle from './BlockTitle';

import feature1 from '../../images/features/statistic.png';
import feature2 from '../../images/features/connection.png';
import feature3 from '../../images/features/activity.png';

const FeatureBlock = ({ img, children }) => (
    <div className={cn(featureStyles.wrapper, 'px-4 py-3 text-left')}>
        <Image src={img}alt="" width={90} height={90} />
        <div className="mt-2">
            {children}
        </div>
    </div>
);

const Features = () => (
    <>
        <BlockTitle>
            <Trans id="index.blockTitle.feature" />
        </BlockTitle>
        <p className={cn(featureStyles.subtitle, 'text-dark mb-5')}>
            <Trans id="index.blockTitle.feature.sub" />
        </p>
        <div className="row">
            <div className="col-12 col-md-4 mb-2">
                <FeatureBlock img={feature1}>
                    <h4>
                        <Trans id="index.feature.1.title" />
                    </h4>
                    <p className="mb-0 text-black-50">
                        <Trans id="index.feature.1.desc" />
                    </p>
                </FeatureBlock>
            </div>
            <div className="col-12 col-md-4 mb-2">
                <FeatureBlock img={feature2}>
                    <h4>
                        <Trans id="index.feature.2.title" />
                    </h4>
                    <p className="mb-0 text-black-50">
                        <Trans id="index.feature.2.desc" />
                    </p>
                </FeatureBlock>
            </div>
            <div className="col-12 col-md-4 mb-2">
                <FeatureBlock img={feature3}>
                    <h4>
                        <Trans id="index.feature.3.title" />
                    </h4>
                    <p className="mb-0 text-black-50">
                        <Trans id="index.feature.3.desc" />
                    </p>
                </FeatureBlock>
            </div>
        </div>
    </>
);

export default Features;
