import React from 'react';
import cn from 'classnames';
import styles from './BlockTitle.module.scss';

const BlockTitle = ({ children }) => (
    <h2 className={cn('text-center mb-5', styles.title)}>
        {children}
    </h2>
);

export default BlockTitle;
