import React from 'react';
import Image from 'next/image';
import cn from 'classnames';

import styles from './Block.module.scss';

const Block = ({ img, children }) => (
    <div className={cn(styles.wrapper, 'd-flex justify-content-center')}>
        <Image src={img} alt="" />
        <span className={cn(styles.text, 'ml-2')}>
            {children}
        </span>
    </div>
);

export default Block;
