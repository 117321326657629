import React from 'react';
import { defineMessage, Trans } from '@lingui/macro';
import cn from 'classnames';

import IndexSystemCard from './IndexSystemCard';
import styles from './IndexSystemList.module.scss';

// Force the list follows this order
const countryList = [
    'TW',
    'US',
    'CA',
    'MX',
    'UK',
    'NO',
];

const countryMessage = {
    TW: defineMessage({ message: 'Taiwan' }),
    US: defineMessage({ message: 'United States' }),
    CA: defineMessage({ message: 'Canada' }),
    MX: defineMessage({ message: 'Mexico' }),
    UK: defineMessage({ message: 'United Kingdom' }),
    NO: defineMessage({ message: 'Norway' }),
};

const IndexSystemList = ({ systemList, spaLink = true }) => {
    const { countries } = systemList;
    return (
        <>
            {/* <h2 className="mb-2" id="systems">
                <Trans id="index.blockTitle.system" />
            </h2> */}
            {countryList.map((country) => {
                if (countries[country]) {
                    return (
                        <section key={country}>
                            <h4 className={styles.locationTitle}>
                                <Trans id={countryMessage[country].id} />
                            </h4>
                            <div className="row" id="system-links">
                                {Object.keys(countries[country]).map((system) => {
                                    const item = countries[country][system];
                                    return (
                                        <div key={system} className={cn('col-md-4 col-sm-6 col-sx-12', styles.cardStation)}>
                                            <IndexSystemCard
                                                system={system}
                                                name={item.name}
                                                stationNum={item.stationNum}
                                                year={item.year}
                                                country={country}
                                                spaLink={spaLink}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </section>
                    )
                }
                return null;
            })}

        </>
    );
};

export default IndexSystemList;
