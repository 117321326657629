import React from 'react';
// import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Home from '../components/Home';
import styles from '../styles/index.module.scss';
import { INDEX_PAGE } from '../utils/static';
import {
    getSystemList,
    getCityNum,
    getAnalyzedMonth,
} from '../utils/getPageProps';

const Index = ({
    systemList, locale, cityNum, analyzedMonth, seo,
}) => (
    <Layout seo={seo} locale={locale}>
        <Home cityNum={cityNum} analyzedMonth={analyzedMonth} systemList={systemList} locale={locale} spaLink={false} />
        {/* <Helmet>
                <script src="https://cdn.moin.bz/widget.js" data-pid={ciaociaoId[locale]} async />
            </Helmet> */}
    </Layout>
);

export async function getStaticProps(context) {
    const { locale } = context;
    return {
        props: {
            systemList: getSystemList(locale),
            locale,
            cityNum: getCityNum(),
            analyzedMonth: getAnalyzedMonth(),
            seo: {
                page: INDEX_PAGE,
            }
        }, // will be passed to the page component as props
    };
}

export default Index;
