import React from 'react';
import { Trans } from '@lingui/macro'
import cn from 'classnames';
import Link from './Link';
import { overviewUrl } from '../utils/urls';
import styles from './IndexSystemCard.module.scss';

const IndexSystemCard = ({
    name, stationNum, year, country, system, spaLink,
}) => (
    <Link href={overviewUrl({ system, year })}>
        <a>
        <div className={cn('card', styles.card, styles[country])}>
            <div className={cn('card-block', styles.cardBlock)}>
                <p className={cn('card-title', styles.cardTitle)}>
                    {name}
                </p>
                <p className={cn('card-subtitle', styles.cardSubtitle)}>
                    {/* {dateRange.map(year => (
                    <span key={year} className={cn('badge', styles.badgeYear)}>
                        <Link to={overviewUrl({ system, year })}>{year}</Link>
                    </span>
                ))} */}
                </p>
                <p className={cn('card-text', styles.cardText)}>
                    {stationNum}
                    {' '}
                    <Trans id="common.stations" />
                </p>
            </div>
        </div>
        </a>
    </Link>
);

export default IndexSystemCard;
