import React from 'react';
import styles from './style.module.scss';

const Button = ({ children, link, type }) => (
    <a className={styles[type]} href={link}>
        {children}
    </a>
);

export default Button;
