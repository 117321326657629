import React from 'react';
import { Trans, t } from '@lingui/macro';
import cn from 'classnames';
import Image from 'next/image';
import styles from './Hero.module.scss';
import Button from '../Button';
import heroImage from './hero.png';

const Hero = ({ locale }) => (
    <div className={cn('container', styles.wrapper)}>
        <div className="row no-gutters">
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
                <div>
                    <h1>
                        <p dangerouslySetInnerHTML={{
                            __html: t({ id: 'index.hero' }),
                        }}
                        />
                    </h1>
                    <p className="mb-5 text-dark">
                        <Trans id="index.hero.sub" />
                    </p>
                    <div className="d-none d-md-block">
                        <Button type="primary" link="#systems">
                            <Trans id="index.hero.look" />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <Image className="img-fluid" src={heroImage} />
            </div>
        </div>
    </div>
);

export default Hero;
