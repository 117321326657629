import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import { useRouter } from 'next/router'
import Image from 'next/image';
import cn from 'classnames';
import styles from './Header.module.scss';
import favIcon from '../../images/favicon.png';
import Link from '../Link';
import {
    aboutUrl,
} from '../../utils/urls';

const Header = ({ locale }) => {
    const [showDropDown, setShowDropDown] = useState(false);
    const router = useRouter();
    function changeLocale(e) {
        const newLocale = e.target.value;
        router.push(router.pathname, router.pathname, {
            locale: newLocale,
        });
    }

    function toggelMobileCollapse() {
        setShowDropDown(!showDropDown);
    }

    return (
        <div className="container p-0">
            <nav className={cn('navbar navbar-expand-lg flex-row-reverse flex-sm-row px-md-0', styles.navbar)} id="navbar">
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggelMobileCollapse}
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <Link href="/">
                    <a className={cn('navbar-brand', styles.navTitle)}>
                        <Image src={favIcon} width={30} height={30} alt="logo" loading="lazy" />
                        <span className="nav-title capitalize pl-2 align-middle">
                            <Trans id="brand" />
                        </span>
                    </a>
                </Link>
                <div className={cn('collapse navbar-collapse', { show: showDropDown })}>
                    <ul className="nav navbar-nav ml-auto">
                        <li className={cn('nav-item', styles.navLink)}>
                            <Link href="/#systems">
                                <a
                                    className="nav-link"
                                >
                                    <Trans id="nav.all.systems" />
                                </a>
                            </Link>
                        </li>
                        <li className={cn('nav-item', styles.navLink)}>
                            <Link
                                href={aboutUrl()}
                            >
                                <a className="nav-link">
                                    <Trans id="nav.about" />
                                </a>
                            </Link>
                        </li>
                        <li className={cn('nav-item d-flex align-items-center', styles.navLinkMobile)}>
                            <select className="custom-select custom-select-sm" value={locale} onChange={changeLocale}>
                                <option value="en">English</option>
                                <option value="zh-hant">繁體中文</option>
                                <option value="zh-hans">简体中文</option>
                            </select>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};
export default (Header);
